import React from 'react';

import Layout from '../components/Layout';
import Contact from '../components/Contact';
import jobData from '../../content/jobs.json';
import certData from '../../content/certifications.json';
import eduData from '../../content/education.json';
import skillData from '../../content/skills.json';

const IndexPage = () => (
  <Layout fullMenu>
    <article id="main">
      <header className='wrapper'>
        <h2>Lebenslauf</h2>
      </header>
      <section className="wrapper style5">
        <Contact />
        <div className="inner"><header><h2>Berufspraxis</h2></header></div>

        {jobData.slice(0, 10).map((value) => {
          return <div className="inner">
            <header style={{ paddingTop: '1.5em' }}>
              {value.beginning}
              <h4>{value.company}</h4>
              <p style={{ margin: '0 0 0.5em 0' }}>{value.title}</p>
            </header>
            {("tasks" in value) ? <>
              <ul className="small">
                {value.tasks.map((task) => {
                  return <li>{task}</li>
                })}
              </ul>
            </> : ''}
          </div>;
        })}


        <div className="inner"><header><hr /><h2>Zertifizierungen</h2></header></div>
        {certData.map((value) => {
          return <div className="inner">
            {value.date}<h4>{value.title}</h4>
          </div>;
        })}

        <div className="inner"><header><hr /><h2>Studium und Schulausbildung</h2></header></div>
        {eduData.map((value) => {
          return <div className="inner">
            {value.date}<h4>{value.title}</h4></div>;
        })}

        <div className="inner"><header><hr /><h2>Sonstige Kenntnisse</h2></header></div>
        {skillData.map((value) => {
          return <div className="inner">
            <header style={{ paddingTop: '1.5em' }}>
              <h4>{value.header}</h4>
            </header>
            {("descriptions" in value) ? <div>
              <ul className="small">
                {value.descriptions.map((description) => {
                  return <li>{description}</li>
                })}
              </ul>
            </div> : ''}
          </div>;
        })}
      </section>

    </article>
  </Layout>
);

export default IndexPage;
