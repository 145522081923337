import React from 'react';
import contactData from '../../content/contact.json';

export default function Contact() {
  return (
    <div id='contact' className='inner align-right show-on-print' style={{ marginTop:'-17em', marginBottom:'0em' }}>
      <b style={{ color: 'black'}}>{contactData.name}</b><br />
      {contactData.address}<br />
      {contactData.zip}&nbsp;{contactData.city}<br />
      Telefon&nbsp;{contactData.phone}<br />
      Mobil&nbsp;{contactData.mobile}<br />
      Mail&nbsp;{contactData.mail}<br />
    </div>
  );
}